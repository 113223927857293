import { apostleLiteSchema } from '@apeiron/apostle'
import {
  listingInfoSchema,
  ListingStatus,
  listingStatusSchema
} from '@apeiron/common'
import {
  BottomStatus,
  CoreType,
  ElementType,
  Planet,
  PlanetAddOn,
  PlanetApostleManageSignature,
  PlanetApostlePayload,
  PlanetClass,
  PlanetClassType,
  PlanetLite,
  PlanetMiddleLite,
  PlanetSkill,
  PlanetSkillAttributes,
  PlanetSkillType,
  PlanetSkillUpgrade,
  PlanetStage,
  PlanetStatus,
  PlanetSuperLite,
  PlanetType,
  PrimevalLegacy,
  RelicsToken
} from '@apeiron/planet'
import { localizeSchema, publicAccountSchema } from '@apeiron/temp'
import { contractWorkStatusSchema } from '@src/deserialize/yup/contract'
import { constructSkillUniqueKeyByValues } from '@src/util/apeiron/planet'
import * as R from 'ramda'
import * as yup from 'yup'

const planetClassTypeSchema: yup.BaseSchema<PlanetClassType> = yup
  .mixed()
  .oneOf(Object.values(PlanetClassType))
  .default(PlanetClassType.All)

const skillTypeSchema: yup.BaseSchema<PlanetSkillType> = yup
  .mixed()
  .oneOf(Object.values(PlanetSkillType))

const elementTypeSchema: yup.BaseSchema<ElementType> = yup
  .mixed()
  .oneOf(Object.values(ElementType))

const planetTypeSchema: yup.BaseSchema<PlanetType> = yup
  .mixed()
  .oneOf(Object.values(PlanetType))
  .default(PlanetType.Normal)

const planetStageSchema: yup.BaseSchema<PlanetStage> = yup
  .mixed()
  .oneOf(Object.values(PlanetStage))

const planetStatusSchema: yup.BaseSchema<PlanetStatus> = yup
  .mixed()
  .oneOf(Object.values(PlanetStatus))

const coreTypeSchema: yup.BaseSchema<CoreType> = yup.mixed().required()

const primevalLegacySchema: yup.SchemaOf<PrimevalLegacy> = yup.object().shape({
  id: yup.string().required(),
  name: yup.string().required(),
  fire: yup.number().required(),
  water: yup.number().required(),
  air: yup.number().required(),
  earth: yup.number().required()
})

const planetSkillAttributesSchema: yup.SchemaOf<PlanetSkillAttributes> = yup
  .object({
    element: elementTypeSchema,
    mana: yup.number().default(0),
    planetClass: planetClassTypeSchema,
    planetType: planetTypeSchema,
    requiredPoints: yup.number().default(0),
    weapon: yup.number().default(0)
  })
  .when(['$data'], (data: Record<string, any>) => {
    return yup.object().default({
      element: R.toLower(
        R.propOr(ElementType.None, 'element', data) as string
      ) as ElementType,
      mana: R.propOr(0, 'mana', data),
      planetClass: R.propOr(PlanetClassType.All, 'planet_class', data),
      planetType: R.propOr(PlanetType.Normal, 'planet_type', data),
      requiredPoints: R.propOr(0, 'skill_point_req', data),
      weapon: R.propOr(0, 'weapon', data)
    })
  })

const planetSkillUpgradeSchema: yup.SchemaOf<PlanetSkillUpgrade> = yup.object({
  description: yup.string().default(''),
  descriptionShort: yup.string().default('')
})

export const planetSkillSchema: yup.SchemaOf<PlanetSkill> = yup
  .object({
    attributes: planetSkillAttributesSchema,
    description: localizeSchema,
    descriptionShort: localizeSchema,
    disabled: yup.boolean().default(false),
    geneId: yup.number().default(0),
    image: yup
      .string()
      .when(
        ['$domain', '$data'],
        (domain: string, data: Record<string, any>) => {
          if (R.isNil(data.ref_id)) return yup.string().default('')
          else {
            const defaultValue = `${domain}planetartworks/planetskill/${data.ref_id}.png`

            return yup.string().default(defaultValue)
          }
        }
      )
      .default(''),
    name: localizeSchema,
    skillId: yup.string().default(''),
    skillUpgrade: yup
      .array()
      .of(planetSkillUpgradeSchema)
      .when(['$data', '$type'], (data: any) => {
        return yup.array().transform((): PlanetSkillUpgrade[] => {
          const skillUpgradeArray = [] as PlanetSkillUpgrade[]

          for (let count: number = 1; count < 4; count++) {
            const found = R.propOr(undefined, `skill_upgrade_${count}`, data)

            if (found) {
              skillUpgradeArray.push({
                description: R.propOr('', 'desc', found),
                descriptionShort: R.propOr('', 'desc_short', found)
              })
            }
          }

          return skillUpgradeArray
        })
      })
      .default([]),
    uniqueKey: yup
      .string()
      .when(['$data', '$type'], (data, type) => {
        return yup
          .string()
          .default(
            constructSkillUniqueKeyByValues(
              type,
              data.planet_class,
              data.skill_id
            )
          )
      })
      .default(''),
    type: skillTypeSchema.when(['$type'], (type: PlanetSkillType) => {
      return skillTypeSchema.default(type)
    })
  })
  .from('desc', 'description', true)
  .from('desc_short', 'descriptionShort', true)
  .from('skill_upgrade_1', 'skillUpgrade', true)
  .from('skill_id', 'geneId', true)
  .from('ref_id', 'skillId', true)
  .noUnknown(true)

const relicsTokenSchema: yup.SchemaOf<RelicsToken> = yup.object({
  id: yup.string().required(),
  image: yup.string().required(),
  name: yup.string().required(),
  thumbnail: yup.string().required(),
  description: yup.string().required()
})

const relicsTokensSchema: yup.SchemaOf<RelicsToken[]> =
  yup.array(relicsTokenSchema)

const bottomStatusSchema: yup.SchemaOf<BottomStatus> = yup.object({
  end: yup.date().default(null),
  start: yup.date().default(null),
  translationKey: yup.string().default('')
})

const addOnSchema: yup.SchemaOf<PlanetAddOn> = yup.object({
  bottomStatus: bottomStatusSchema.nullable().default(undefined),
  disabled: yup.boolean().default(false),
  isBookmarked: yup.boolean().default(false),
  selected: yup.boolean().default(false),
  workStatus: contractWorkStatusSchema
})

export const primevalLegaciesSchema: yup.SchemaOf<PrimevalLegacy[]> = yup
  .array()
  .of(primevalLegacySchema.required())

export const planetClassSchema: yup.SchemaOf<PlanetClass> = yup
  .object({
    image: yup.string().default(''),
    name: yup.string().default(''),
    type: yup.number().default(PlanetClassType.All)
  })
  .from('image_path', 'image')
  .noUnknown(true)

export const planetSuperLiteSchema: yup.SchemaOf<PlanetSuperLite> = yup
  .object({
    age: yup
      .number()
      .transform(value => Number(value.toFixed(2)))
      .default(0),
    name: yup.string().default(''),
    planetID: yup.string().default('0')
  })
  .from('ageDisplay', 'age')
  .noUnknown(true)

export const planetLiteSchema: yup.SchemaOf<PlanetLite> = planetSuperLiteSchema
  .concat(addOnSchema)
  .concat(
    yup
      .object({
        air: yup.number().default(0),
        bornAt: yup.date().nullable().default(null),
        breedAt: yup.string().nullable().default(''),
        breedCount: yup.number().default(0),
        breedCountMax: yup.number().default(0),
        childrenIDs: yup.array().of(yup.number()).default([]),
        coreType: coreTypeSchema.default(CoreType.None),
        earth: yup.number().default(0),
        fire: yup.number().default(0),
        generation: yup.number().default(0),
        image: yup.string().nullable().default(undefined),
        listingInfo: listingInfoSchema.nullable().default(undefined),
        owner: publicAccountSchema.nullable(),
        parentIDs: yup.array().of(yup.number()).default([]),
        planetClass: planetClassTypeSchema,
        planetCreateTime: yup.date().nullable().default(null),
        planetType: planetTypeSchema.default(PlanetType.Normal),
        price: yup
          .number()
          .transform((value: number) => {
            return isNaN(value) ? 0 : value
          })
          .default(0),
        relicsTokens: relicsTokensSchema.default([]),
        sale: yup
          .boolean()
          .transform((value: ListingStatus) => {
            return value === ListingStatus.LISTING
          })
          .default(false),
        seed: yup.boolean().default(false),
        status: planetStatusSchema.default(PlanetStatus.Enable),
        stage: planetStageSchema.default(PlanetStage.Core),
        trackCanAttachAt: yup.date().nullable().default(null),
        water: yup.number().default(0)
      })
      .from('availableAttachTime', 'trackCanAttachAt')
      .from('bornTime', 'bornAt')
      .from('lastBreedTime', 'breedAt')
      .from('priceInUSD', 'price')
      .from('nftListingStatus', 'sale', true)
  )
  .noUnknown(true)

export const planetMiddleLiteSchema: yup.SchemaOf<PlanetMiddleLite> =
  planetLiteSchema
    .concat(
      yup
        .object({
          relicApostles: yup
            .array()
            .of(
              yup
                .object({
                  apostle: apostleLiteSchema,
                  id: yup.string().default(''),
                  planetApostleId: yup.string().default('')
                })
                .transform(value => {
                  if (!value.apostleCommon) value.apostleCommon = {} // Ensure apostle object exists

                  return {
                    id: value.apostleID,
                    planetApostleId: value.planetApostleID,
                    apostle: { ...value.apostleCommon, id: value.apostleID }
                  }
                })
            )
            .transform((value: any) => (R.isNil(value) ? [] : value)),
          relicApostleLimit: yup.number().default(0)
        })
        .from('relicApostleSlot.ageSlotCount', 'relicApostleLimit')
    )
    .noUnknown(true)

export const planetSchema: yup.SchemaOf<Planet> = planetMiddleLiteSchema
  .concat(
    yup.object({
      armor: yup.number().default(0),
      atkPow: yup.number().default(0),
      atkRange: yup.number().default(0),
      atkSpeed: yup.number().default(0),
      battlePassLock: yup.boolean().default(false),
      baseAge: yup.number().default(0),
      bodyProps: yup.number().default(0),
      children: yup.array(planetLiteSchema.nullable()).nullable().default([]),
      critChance: yup.number().default(0),
      critDmg: yup.number().default(0),
      cSkill1: yup.number().default(0),
      cSkill2: yup.number().default(0),
      cSkill3: yup.number().default(0),
      cSkill: yup
        .array()
        .of(yup.number())
        .when(['$data'], (data: Record<string, any>) => {
          return yup
            .array()
            .default([data.cSkill1 || 0, data.cSkill2 || 0, data.cSkill3 || 0])
        })
        .default([]),
      energy: yup.number().default(0),
      evolve: yup.number().default(0),
      gender: yup.number().default(0),
      headProps: yup.number().default(0),
      health: yup.number().default(0),
      nftListingStatus: listingStatusSchema,
      parents: yup.array(planetLiteSchema.nullable()).nullable().default([]),
      primevalLegacy: yup.number().default(0),
      pSkill1: yup.number().default(0),
      pSkill2: yup.number().default(0),
      pSkill: yup
        .array()
        .of(yup.number())
        .when(['$data'], (data: Record<string, any>) => {
          return yup.array().default([data.pSkill1 || 0, data.pSkill2 || 0])
        })
        .default([]),
      specialGene: yup.number().default(0),
      weapon: yup.number().default(0)
    })
  )
  .noUnknown(true)

const planetApostlePayload: yup.SchemaOf<PlanetApostlePayload> = yup
  .object({
    actions: yup.array().of(yup.number()).default([]),
    apostleIds: yup.array().of(yup.string()).default([]),
    createTimes: yup.array().of(yup.number()).default([]),
    genes: yup.array().of(yup.string()).default([]),
    iVs: yup.array().of(yup.number()).default([]),
    planetId: yup.string().default(''),
    seedPlanetId: yup.string().default(''),
    slotsWithApostlesIds: yup.array().of(yup.string()).default([])
  })
  .from('apostleIDs', 'apostleIds')
  .from('planetID', 'planetId')
  .from('seedPlanetID', 'seedPlanetId')
  .from('slotsWithApostleIDs', 'slotsWithApostlesIds')

export const planetApostleManageSignatureSchema: yup.SchemaOf<PlanetApostleManageSignature> =
  yup.object({
    signature: yup.string().required(),
    timestamp: yup.number().required(),
    managementPayloads: yup.array().of(planetApostlePayload)
  })
