import ChinaSVG from '@public/icons/icon-flag-china.svg'
import EnglandSVG from '@public/icons/icon-flag-england.svg'
import FrenchSVG from '@public/icons/icon-flag-french.svg'
import HongKongSVG from '@public/icons/icon-flag-hongkong.svg'
import JapanSVG from '@public/icons/icon-flag-japan.svg'
import KoreaSVG from '@public/icons/icon-flag-korea.svg'
import PhilippineSVG from '@public/icons/icon-flag-philippine.svg'
import PotugualSVG from '@public/icons/icon-flag-portugual.svg'
import RussiaSVG from '@public/icons/icon-flag-russia.svg'
import ThailandSVG from '@public/icons/icon-flag-thailand.svg'
import UkraineSVG from '@public/icons/icon-flag-ukraine.svg'
import VietnamSVG from '@public/icons/icon-flag-vietnam.svg'
import * as R from 'ramda'

import {
  _LanguageType as LanguageType,
  LanguageTypeSupported
} from './languageSupported'

export { LanguageType }

export const AllLanguages = [
  {
    image: EnglandSVG,
    name: 'language.english',
    type: LanguageType.English
  },
  {
    image: ChinaSVG,
    name: 'language.chinese_simplified',
    type: LanguageType.ChineseSimplified
  },
  {
    image: HongKongSVG,
    name: 'language.chinese_traditional',
    type: LanguageType.ChineseTraditional
  },
  {
    image: KoreaSVG,
    name: 'language.korean',
    type: LanguageType.Korean
  },
  {
    image: JapanSVG,
    name: 'language.japanese',
    type: LanguageType.Japanese
  },
  {
    image: ThailandSVG,
    name: 'language.thai',
    type: LanguageType.Thai
  },
  {
    image: PhilippineSVG,
    name: 'language.filipino',
    type: LanguageType.Filipino
  },
  {
    image: VietnamSVG,
    name: 'language.vietnamese',
    type: LanguageType.Vietnamese
  },
  {
    image: FrenchSVG,
    name: 'language.french',
    type: LanguageType.French
  },
  {
    image: PotugualSVG,
    name: 'language.portuguese',
    type: LanguageType.Portuguese
  },
  {
    image: RussiaSVG,
    name: 'language.russian',
    type: LanguageType.Russian
  },
  {
    image: UkraineSVG,
    name: 'language.ukrainian',
    type: LanguageType.Ukrainian
  }
]

export const Languages = R.filter(
  language => R.includes(language.type, LanguageTypeSupported),
  AllLanguages
)
