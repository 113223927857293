// ! add language type here, but don't import any other file, as it also used by next.config.js
export enum _LanguageType {
  ChineseSimplified = 'zh-CN',
  ChineseTraditional = 'zh-TW',
  English = 'en-US',
  Filipino = 'tl',
  French = 'fr',
  Japanese = 'ja',
  Korean = 'kr',
  Portuguese = 'pt-PT',
  Russian = 'ru',
  Thai = 'th',
  Ukrainian = 'uk',
  Vietnamese = 'vi'
}

export const LanguageTypeSupported = [
  _LanguageType.ChineseTraditional,
  _LanguageType.ChineseSimplified,
  _LanguageType.English,
  _LanguageType.Filipino,
  _LanguageType.French,
  _LanguageType.Japanese,
  _LanguageType.Korean,
  _LanguageType.Thai,
  _LanguageType.Vietnamese
]

export default { _LanguageType, LanguageTypeSupported }
