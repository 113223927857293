import { gql } from '@apollo/client'

export const OwnerBalanceFragment = gql`
  fragment OwnerBalance on OwnerBalanceInfo {
    balance
    listingCount
    stakeCount
  }
`

export const GodiverseMetaLiteFragment = gql`
  fragment GodiverseMetaLite on GodiverseMeta {
    agingBuffValue
    canAttachToSlots
    itemID
    itemType
    minTierRequired
    name
    orbitTrack
    tier
  }
`

export const GodiverseMetaFragment = gql`
  fragment GodiverseMeta on GodiverseMeta {
    ...GodiverseMetaLite
    totalCount
  }

  ${GodiverseMetaLiteFragment}
`
