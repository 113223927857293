import { DEFAULT_ANIMATE_DURATION, NextImage } from '@apeiron/temp'
import { Button, Menu, MenuItem, styled } from '@mui/material'
import GlobeSVG from '@public/icons/icon-globe.svg'
import { Languages, LanguageType } from '@src/constants/language'
import { useRouter } from 'next/router'
import * as R from 'ramda'
import { MouseEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

const Icon = styled(NextImage)`
  aspect-ratio: 1;
  width: 100%;
`

const NavButton = styled(Button)`
  aspect-ratio: 1;
  min-width: unset;
  padding: 2px;
  width: 32px;
`

const StyledMenuList = styled(Menu)`
  .MuiPaper-root {
    top: 56px;
    left: unset;
    right: 0px;
    background-color: unset;
    overflow: visible;
    width: 280px;
  }
  .MuiMenu-list {
    background: #151615;
    box-shadow: 0px 8px 32.432px 0px rgba(0, 0, 0, 0.65);
    overflow: hidden;
    padding: 20px;
  }
`

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  font-size: 14px;
  font-weight: 300;
  gap: 14px;
  padding: 12px 14px;
  transition: background-color ${DEFAULT_ANIMATE_DURATION}ms;
  &.MuiMenuItem-divider {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  :hover {
    background-color: #55585a;
  }
`

const MenuItemIcon = styled(NextImage)`
  aspect-ratio: 1;
  flex-shrink: 0;
  width: 20px;
`

const LanguageButton = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const router = useRouter()

  const isMenuOpen = Boolean(anchorEl)

  const { t } = useTranslation()

  const handleOnShareButtonClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
    },
    []
  )

  const handleOnClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleOnClick = useCallback(
    (type: LanguageType) => {
      router.push(router.pathname, router.asPath, { locale: type })
      handleOnClose()
    },
    [handleOnClose, router]
  )

  return (
    <>
      <NavButton onClick={handleOnShareButtonClick}>
        <Icon src={GlobeSVG} />
      </NavButton>
      <StyledMenuList
        anchorEl={anchorEl}
        anchorReference='none'
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={isMenuOpen}
        onClose={handleOnClose}
      >
        {R.map(
          language => (
            <StyledMenuItem
              key={language.type}
              onClick={() => handleOnClick(language.type)}
            >
              <MenuItemIcon src={language.image} />
              {t(language.name)}
            </StyledMenuItem>
          ),
          Languages
        )}
      </StyledMenuList>
    </>
  )
}

export default LanguageButton
