const ENV_CONFIG = {
  API_URL: {
    APP_CONNECT: process.env.NEXT_PUBLIC_API_URL_APP_CONNECT,
    EXPEDITION: process.env.NEXT_PUBLIC_API_URL_EXPEDITION,
    MARKETPLACE: process.env.NEXT_PUBLIC_API_URL_MARKETPLACE
  },
  REST_API_URL: {
    MARKETPLACE: process.env.NEXT_PUBLIC_REST_API_URL_MARKETPLACE
  },
  ID: {
    FINGERPRINT: process.env.NEXT_PUBLIC_ID_FINGERPRINT_API_KEY,
    FRACTAL: process.env.NEXT_PUBLIC_ID_FRACTAL,
    GEETEST: process.env.NEXT_PUBLIC_ID_GEETEST,
    GOOGLE_TAG_MANAGER: process.env.NEXT_PUBLIC_ID_GOOGLE_TAG_MANAGER,
    WALLETCONNECT: process.env.NEXT_PUBLIC_ID_WALLETCONNECT
  },
  DOMAIN_URL: {
    ASSET: process.env.NEXT_PUBLIC_URL_DOMAIN_ASSET,
    JSON: process.env.NEXT_PUBLIC_URL_DOMAIN_JSON,
    SKILL_VIDEO: process.env.NEXT_PUBLIC_URL_DOMAIN_SKILL_VIDEO
  },
  EMAIL_URL: {
    SUPPORT: process.env.NEXT_PUBLIC_EMAIL_SUPPORT
  },
  EXTERNAL_URL: {
    APEIRON: {
      MEDIA_KIT: `${process.env.NEXT_PUBLIC_WEB_URL_OFFICIAL}${process.env.NEXT_PUBLIC_PATH_MEDIA_KIT}`,
      PRIVACY: `${process.env.NEXT_PUBLIC_WEB_URL_OFFICIAL}${process.env.NEXT_PUBLIC_PATH_PRIVACY}`,
      TERMS: `${process.env.NEXT_PUBLIC_WEB_URL_OFFICIAL}${process.env.NEXT_PUBLIC_PATH_TERMS}`
    },
    APP_LINK: {
      GAME: process.env.NEXT_PUBLIC_URL_APP_LINK_GAME
    },
    BRANDING: {
      CHARACTER: `${process.env.NEXT_PUBLIC_WEB_URL_BRAND_WEBSITE}${process.env.NEXT_PUBLIC_PATH_CHARACTER}`
    },
    DISCORD: {
      CHECK_IN: process.env.NEXT_PUBLIC_URL_DISCORD_CHECK_IN,
      DOODARIA: process.env.NEXT_PUBLIC_URL_DISCORD_DOODARIA
    },
    LOOTRUSH: {
      APOSTLE: process.env.NEXT_PUBLIC_URL_LOOTRUSH_APOSTLE,
      PLANET: process.env.NEXT_PUBLIC_URL_LOOTRUSH_PLANET,
      STAR: process.env.NEXT_PUBLIC_URL_LOOTRUSH_STAR,
      APEIRON: process.env.NEXT_PUBLIC_URL_LOOTRUSH
    },
    OPENSEA: process.env.NEXT_PUBLIC_URL_OPENSEA,
    POLYGON: process.env.NEXT_PUBLIC_POLYGON_TRANSACTION_URL,
    MAVIS_MARKET_GODIVERSE: process.env.NEXT_PUBLIC_URL_MAVIS_MARKET_GODIVERSE,
    WALLET: {
      METAMASK: {
        CHROME: process.env.NEXT_PUBLIC_URL_INSTALL_METAMASK_ON_CHROME,
        FIREFOX: process.env.NEXT_PUBLIC_URL_INSTALL_METAMASK_ON_FIREFOX
      },
      RONIN: {
        CHROME: process.env.NEXT_PUBLIC_URL_INSTALL_RONIN_ON_CHROME,
        FIREFOX: process.env.NEXT_PUBLIC_URL_INSTALL_RONIN_ON_FIREFOX,
        SWAP_CURRENCY: {
          RON_TO_WRON: process.env.NEXT_PUBLIC_URL_RONIN_SWAP_WRON
        },
        LIQUIDITY_POOL: {
          ANIMA_WRON:
            process.env.NEXT_PUBLIC_URL_RONIN_LIQUIDITY_POOL_ANIMA_WRON,
          APRS_WRON: process.env.NEXT_PUBLIC_URL_RONIN_LIQUIDITY_POOL_APRS_WRON
        },
        TUTORIAL: {
          OFFICIAL: process.env.NEXT_PUBLIC_URL_RONIN_WALLET,
          VRF: process.env.NEXT_PUBLIC_URL_RONIN_VRF
        },
        DOWNLOAD: process.env.NEXT_PUBLIC_URL_RONIN_WALLET
      },
      OKX: {
        DOWNLOAD: process.env.NEXT_PUBLIC_URL_OKX_WALLET
      }
    },
    WEBSTORE: {
      GIFT_CODE: `${process.env.NEXT_PUBLIC_WEB_URL_STORE}${process.env.NEXT_PUBLIC_PATH_GIFT_CODE}`,
      VIP_SHOP: `${process.env.NEXT_PUBLIC_WEB_URL_STORE}${process.env.NEXT_PUBLIC_PATH_VIP_SHOP}`
    }
  },
  ROUTE: {
    SSO: process.env.NEXT_PUBLIC_PATH_SSO_CALLBACK
  },
  NODE_ENV: process.env.NODE_ENV,
  JSON_FILE_NAME: {
    ACTIVTY_FILTERS: process.env.NEXT_PUBLIC_STATIC_JSON_ACTIVITY_FILTERS,
    APOSTLE: process.env.NEXT_PUBLIC_STATIC_JSON_APOSTLE,
    APOSTLE_FILTERS: process.env.NEXT_PUBLIC_STATIC_JSON_APOSTLE_FILTERS,
    APOSTLE_TICKETS: process.env.NEXT_PUBLIC_STATIC_JSON_APOSTLE_TICKETS,
    APP_CONNECTIONS: process.env.NEXT_PUBLIC_STATIC_JSON_APP_CONNECTIONS,
    ASTRONOMICAL_FILTERS:
      process.env.NEXT_PUBLIC_STATIC_JSON_ASTRONOMICAL_FILTERS,
    BATTLE_DEMO_ABOUT: process.env.NEXT_PUBLIC_STATIC_JSON_BATTLE_DEMO_ABOUT,
    BATTLE_DEMO_FRAGMENTS:
      process.env.NEXT_PUBLIC_STATIC_JSON_BATTLE_DEMO_FRAGMENTS,
    BOUNTY_APOSTLE_FILTERS:
      process.env.NEXT_PUBLIC_STATIC_JSON_BOUNTY_APOSTLE_FILTERS,
    CONJUNCT_COST: process.env.NEXT_PUBLIC_STATIC_JSON_CONJUNCT_COST,
    CONSTELLATIONS: process.env.NEXT_PUBLIC_STATIC_JSON_CONSTELLATIONS,
    DATA_DICTIONARY: process.env.NEXT_PUBLIC_STATIC_JSON_DATA_DICTIONARY,
    DATA_PATH: process.env.NEXT_PUBLIC_STATIC_JSON_DATA_PATH,
    DOWNLOAD: process.env.NEXT_PUBLIC_STATIC_JSON_DOWNLOAD,
    EXPEDITION_REWARD_QUANTITY:
      process.env.NEXT_PUBLIC_STATIC_JSON_EXPEDITION_REWARD_QUANTITY,
    ORBIT_FILTERS: process.env.NEXT_PUBLIC_STATIC_JSON_ORBIT_FILTERS,
    PAGE_BUILDER_BEGINNERS_GUIDE_INDEX:
      process.env.NEXT_PUBLIC_STATIC_JSON_PAGE_BUILDER_BEGINNERS_GUIDE_INDEX,
    PAGE_BUILDER_BREEDING_GUIDE:
      process.env.NEXT_PUBLIC_STATIC_JSON_PAGE_BUILDER_BREEDING_GUIDE,
    PAGE_BUILDER_BUILDS_GUIDE:
      process.env.NEXT_PUBLIC_STATIC_JSON_PAGE_BUILDER_BUILDS_GUIDE,
    PAGE_BUILDER_COLLECTORS_GUIDE:
      process.env.NEXT_PUBLIC_STATIC_JSON_PAGE_BUILDER_COLLECTORS_GUIDE,
    PAGE_BUILDER_ECONOMY:
      process.env.NEXT_PUBLIC_STATIC_JSON_PAGE_BUILDER_ECONOMY,
    PAGE_BUILDER_GAMEPLAY_GUIDE:
      process.env.NEXT_PUBLIC_STATIC_JSON_PAGE_BUILDER_GAMEPLAY_GUIDE,
    PAGE_BUILDER_PLANET_SCHOLARSHIP:
      process.env.NEXT_PUBLIC_STATIC_JSON_PAGE_BUILDER_PLANET_SCHOLARSHIP,
    PLANET_FILTERS: process.env.NEXT_PUBLIC_STATIC_JSON_PLANET_FILTERS,
    PLANET_TYPE_GROUPS: process.env.NEXT_PUBLIC_STATIC_JSON_PLANET_TYPE_GROUPS,
    PRIMEVAL_LEGACY: process.env.NEXT_PUBLIC_STATIC_JSON_PRIMEVAL_LEGACY,
    SSO: process.env.NEXT_PUBLIC_STATIC_JSON_SSO,
    STAR_FILTERS: process.env.NEXT_PUBLIC_STATIC_JSON_STAR_FILTERS,
    TREASURE: process.env.NEXT_PUBLIC_STATIC_JSON_EXPEDITION_ITEMS,
    VIP_LEVELS: process.env.NEXT_PUBLIC_STATIC_JSON_VIP_LEVELS,
    VIP_PRIVILEGES: process.env.NEXT_PUBLIC_STATIC_JSON_VIP_PRIVILEGES,
    VIP_STAKE_BANNERS: process.env.NEXT_PUBLIC_STATIC_JSON_VIP_STAKE_BANNERS,
    VIP_LP_TOKEN_TUTORIAL:
      process.env.NEXT_PUBLIC_STATIC_JSON_VIP_LP_TOKEN_TUTORIAL
  },
  JSON_URL: {
    APOSTLE_LEVELS: process.env.NEXT_PUBLIC_STATIC_JSON_APOSTLE_LEVELS,
    APOSTLE_IV: process.env.NEXT_PUBLIC_STATIC_JSON_APOSTLE_IV,
    APOSTLE_SKILLS: process.env.NEXT_PUBLIC_STATIC_JSON_APOSTLE_SKILLS,
    BATTLE_DEMO_CONFIG: process.env.NEXT_PUBLIC_STATIC_JSON_BATTLE_DEMO_CONFIG,
    CARD_SKILLS: process.env.NEXT_PUBLIC_STATIC_JSON_CARD_SKILLS,
    CURRENT_SEASON: process.env.NEXT_PUBLIC_STATIC_JSON_CURRENT_SEASON,
    SKILL_GLOSSARY: process.env.NEXT_PUBLIC_STATIC_JSON_SKILL_GLOSSARY
  },
  THIRD_PARTY_API_URL: {
    CAPTCHA: process.env.NEXT_PUBLIC_CAPTCHA_SERVER_URL,
    COMMUNITY_REGISTER: process.env.NEXT_PUBLIC_URL_COMMUNITY_REGISTER,
    SKY_MAVIS_RPC: process.env.NEXT_PUBLIC_URL_SKY_MAVIS_RPC
  },
  WEB_URL: {
    MARKETPLACE: process.env.NEXT_PUBLIC_WEB_URL_MARKETPLACE,
    MARKETPLACE_LEGACY: process.env.NEXT_PUBLIC_WEB_URL_MARKETPLACE_LEGACY,
    OFFICIAL: process.env.NEXT_PUBLIC_WEB_URL_OFFICIAL,
    BRAND_WEBSITE: process.env.NEXT_PUBLIC_WEB_URL_BRAND_WEBSITE,
    STAR_MINT: process.env.NEXT_PUBLIC_WEB_URL_STAR_MINT,
    STORE: process.env.NEXT_PUBLIC_WEB_URL_STORE
  },
  VARIABLES: {
    ENV: process.env.NEXT_PUBLIC_ENV || 'development',
    IS_TESTNET:
      (process.env.NEXT_PUBLIC_NETWORK_TESTNET_OR_MAINNET || 'testnet') ===
      'testnet',
    NAME: process.env.NEXT_PUBLIC_APP_NAME
  }
}

export default ENV_CONFIG
